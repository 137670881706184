<template>
  <v-container fluid class="pt-0">
    <!-- MENÜSOR START -->
    <v-row class="pa-3 primary">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon color="white" class="mr-3" v-on="on" @click="$router.go(-1)">
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </template>
        <span>
              {{ $t('button.backToList') }}
            </span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon color="white" class="mr-3" v-on="on" v-if="$route.params.ws !== 'uj-megrendeles'">
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('button.edit') }}
        </span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon color="white" class="mr-3" v-on="on" @click="sendAppleForm" :disabled="!appleForm">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('button.save') }}
        </span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon color="white" class="mr-3" v-on="on" :disabled="!appleForm">
            <v-icon>
              mdi-printer
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('button.print') }}
        </span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <!--v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon color="white" class="mr-3" v-on="on">
            <v-icon>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('button.export') }}
        </span>
      </v-tooltip-->
    </v-row>
    <!-- MENÜSOR END -->
    <v-form v-model="appleForm">
      <v-row class="pt-6">
        <!-- ESZKÖZ ADATOK START -->
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-devices</v-icon>
              <span class="subtitle-1">{{ $t('stepper.deviceDetails') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-6">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete v-model="worksheet.serviceRequest.brand"
                                  :items="selectFieldOptions.brands"
                                  :label="$t('inputLabels.manufacturer')"
                                  item-value="id"
                                  item-text="name"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  filled
                                  disabled
                                  return-object
                                  required>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select v-model="worksheet.serviceRequest.productGroup"
                            :items="selectFieldOptions.productGroups"
                            item-text="displayName"
                            item-value="id"
                            :no-data-text="$t('noDataText.productGroups')"
                            :label="$t('inputLabels.productGroup')"
                            :rules="inputRules.generalRules"
                            :hint="$t('inputMessages.requiredField')"
                            persistent-hint
                            filled
                            return-object
                            required>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete v-model="worksheet.serviceRequest.productTypeFull"
                                  :items="selectFieldOptions.productTypes"
                                  item-text="displayName"
                                  item-value="gsmTipusId"
                                  :search-input.sync="typeSearch"
                                  :label="$t('inputLabels.productType')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  :no-data-text="$t('noDataText.general')"
                                  persistent-hint
                                  filled
                                  return-object
                                  required>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="worksheet.serviceRequest.uniqueIdentifierIn1"
                                    :label="worksheet.serviceRequest.productGroup.nameUniqueIdentifier1  || $t('inputLabels.imei1')"
                                    :rules="worksheet.serviceRequest.productGroup.reqUniqueIdentifier1 ? inputRules.imeiRule : []"
                                    :messages="worksheet.serviceRequest.productGroup.reqUniqueIdentifier1 ? $t('imeiHint') : $t('imeiHintOptional')"
                                    :hint="worksheet.serviceRequest.productGroup.reqUniqueIdentifier1 ? $t('imeiHint') : $t('imeiHintOptional') "
                                    v-mask="imeiMask"
                                    counter="15"
                                    maxlength="15"
                                    persistent-hint
                                    filled
                                    :disabled="!worksheet.serviceRequest.productGroup.isUniqueIdentifier1">
                      </v-text-field>
                      <v-slide-y-transition>
                        <v-row class="pa-0 ma-0" v-show="worksheet.serviceRequest.productGroup.reqUniqueIdentifier1">
                          <v-col class="px-3 py-0 caption">
                            {{ $t('generateRandomImei') }}
                          </v-col>
                          <v-col cols="auto" class="pa-0">
                            <v-btn small
                                   color="primary"
                                   @click="randomImei(1)">
                              {{ $t('button.generate') }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-slide-y-transition>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="worksheet.serviceRequest.uniqueIdentifierIn2"
                                    :label="worksheet.serviceRequest.productGroup.nameUniqueIdentifier2 || $t('inputLabels.imei2')"
                                    :rules="worksheet.serviceRequest.productGroup.reqUniqueIdentifier2 ? inputRules.imeiRule : []"
                                    :hint="worksheet.serviceRequest.productGroup.reqUniqueIdentifier2 ? $t('imeiHint') : $t('inputMessages.optionalField')"
                                    :messages="worksheet.serviceRequest.productGroup.reqUniqueIdentifier2 ? $t(`${imeiHint}`) : $t('imeiHintOptional')"
                                    v-mask="imeiMask"
                                    counter="15"
                                    maxlength="15"
                                    persistent-hint
                                    filled
                                    :disabled="!worksheet.serviceRequest.productGroup.isUniqueIdentifier2">
                      </v-text-field>
                      <v-slide-y-transition>
                        <v-row class="pa-0 ma-0" v-show="worksheet.serviceRequest.productGroup.reqUniqueIdentifier2">
                          <v-col class="px-3 py-0 caption">
                            {{ $t('generateRandomImei') }}
                          </v-col>
                          <v-col cols="auto" class="pa-0">
                            <v-btn small
                                   color="primary"
                                   @click="randomImei(2)">
                              {{ $t('button.generate') }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-slide-y-transition>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="worksheet.serviceRequest.uniqueIdentifierIn3"
                                    :label="worksheet.serviceRequest.productGroup.nameUniqueIdentifier3  || $t('inputLabels.serialNr')"
                                    :rules="worksheet.serviceRequest.productGroup.reqUniqueIdentifier3 ? inputRules.snRule : []"
                                    :hint="worksheet.serviceRequest.productGroup.reqUniqueIdentifier3 ? $t('inputMessages.requiredField') : $t('inputMessages.optionalField')"
                                    counter="22"
                                    maxlength="22"
                                    persistent-hint
                                    filled
                                    :disabled="!worksheet.serviceRequest.productGroup.isUniqueIdentifier3">
                      </v-text-field>
                      <v-slide-y-transition>
                        <v-row class="pa-0 ma-0" v-show="worksheet.serviceRequest.productGroup.reqUniqueIdentifier3">
                          <v-col class="px-3 py-0 caption">
                            {{ $t('generateRandomImei') }}
                          </v-col>
                          <v-col cols="auto" class="pa-0">
                            <v-btn small
                                   color="primary"
                                   @click="randomImei(3)">
                              {{ $t('button.generate') }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-slide-y-transition>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="worksheet.serviceRequest.dateOfPurchase"
                    :label="$t('inputLabels.dateOfPurchase')"
                    :rules="inputRules.purchaseDateRule"
                    :hint="$t('inputMessages.purchaseDateReq')"
                    persistent-hint
                    v-mask="dateTextMask"
                    filled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select v-model="worksheet.serviceRequest.warrantyType"
                            :items="selectFieldOptions.warrantyType"
                            item-text="name"
                            item-value="id"
                            :label="$t('inputLabels.warrantyType2')"
                            :rules="inputRules.selectRules"
                            :hint="$t('inputMessages.requiredField')"
                            persistent-hint
                            filled
                            return-object
                            required>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field v-model="worksheet.serviceRequest.purchasePrice"
                                :label="$t('inputLabels.purchasePrice')"
                                :hint="$t('inputMessages.optionalField')"
                                suffix="Ft"
                                persistent-hint
                                filled
                                v-mask="priceMask"
                                required>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field v-model="worksheet.calculatedRepairPrice"
                                :label="$t('inputLabels.repairCostLimit')"
                                :hint="$t('inputMessages.optionalField')"
                                suffix="Ft"
                                v-mask="priceMask"
                                persistent-hint
                                filled/>
                </v-col>
                <!--v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="worksheet.serviceRequest.dateOfDelivery"
                    :label="$t('inputLabels.dateOfDelivery')"
                    :rules="inputRules.purchaseDateRule"
                    :hint="$t('inputMessages.purchaseDateReq')"
                    persistent-hint
                    v-mask="dateTextMask"
                    filled>
                  </v-text-field>
                </v-col-->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- ESZKÖZ ADATOK END -->
        <!-- FIZIKAI START -->
        <v-col cols="12" sm="6">
          <v-card class="fill-height">
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-wrench</v-icon>
              <span class="subtitle-1">{{ $t('damages') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-6">
              <v-row>
                <v-col cols="12">
                  <v-textarea v-model="worksheet.physicalDamages"
                              :label="$t('physicalDamages')"
                              :hint="$t('inputMessages.optionalField')"
                              rows="3"
                              persistent-hint
                              clearable
                              counter="255"
                              maxlength="255"
                              filled/>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="worksheet.aestheticFaults"
                              :label="$t('cosmeticDamages')"
                              :hint="$t('inputMessages.optionalField')"
                              rows="3"
                              persistent-hint
                              clearable
                              counter="255"
                              maxlength="255"
                              filled/>
                </v-col>
              </v-row>
              <!--v-col cols="12">
                <v-select v-model="additionalDamages.additionalDamageData.physicalDamage.physicalDamageTypeId"
                          :items="selectFieldOptions.physicalDamages"
                          item-value="physicalDamageTypeId"
                          item-text="physicalDamageTypeName"
                          :label="$t('physicalDamages')"
                          :hint="$t('inputMessages.optionalField')"
                          persistent-hint
                          clearable
                          filled>
                </v-select>
              </v-col-->
            </v-card-text>
          </v-card>
        </v-col>
        <!-- FIZIKAI END -->
        <!-- KOZMETIKAI START -->
        <!--v-col cols="12" sm="6" md="4">
          <v-card>
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-wrench</v-icon>
              <span class="subtitle-1">{{ $t('cosmeticDamages') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-6">
              <v-row>
                <v-col cols="12">
                  <v-textarea v-model="additionalDamages.additionalDamageData.aestheticFault.aestheticFaultTypeDescription"
                              :label="$t('inputLabels.comment')"
                              :hint="$t('inputMessages.optionalField')"
                              rows="3"
                              persistent-hint
                              clearable
                              counter="255"
                              maxlength="255"
                              filled/>
                </v-col>
              </v-row-->
              <!--v-col cols="12">
                <v-select v-model="additionalDamages.additionalDamageData.aestheticFault.aestheticFaultTypeId"
                          :items="selectFieldOptions.aestheticFaults"
                          item-value="aestheticDamageTypeId"
                          item-text="aestheticDamageTypeName"
                          :label="$t('cosmeticDamages')"
                          :hint="$t('inputMessages.optionalField')"
                          persistent-hint
                          clearable
                          filled>
                </v-select>
              </v-col-->
            <!--/v-card-text>
          </v-card>
        </v-col-->
        <!-- KOZMETIKAI END -->
        <!-- TOVÁBBI START -->
        <v-col cols="12" sm="6">
          <v-card class="fill-height">
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-cellphone-information</v-icon>
              <span class="subtitle-1">{{ $t('additionalDatas') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-6">
              <v-row>
                <v-col cols="12">
                  <v-textarea v-model="worksheet.serviceRequest.errorDescription"
                              :label="$t('inputLabels.troubleDescription')"
                              :rules="inputRules.generalRules"
                              :hint="$t('inputMessages.requiredField')"
                              rows="3"
                              persistent-hint
                              filled/>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="worksheet.serviceRequest.accessories"
                              :label="$t('inputLabels.accessories')"
                              :hint="$t('inputMessages.optionalField')"
                              rows="3"
                              persistent-hint
                              filled/>
                </v-col>
                <!--v-col cols="12">
                  <v-file-input v-model="files"
                                :label="$t('inputLabels.attachedDocs')"
                                :rules="inputRules.maxFileSizeOptional"
                                :hint="$t('inputMessages.maxFileSizeRuleOptional')"
                                persistent-hint
                                multiple
                                accept="image/*, .pdf, .doc, .docx"
                                counter
                                show-size
                                :disabled="inputsDisabled"
                                filled
                                ref="fileInput">
                    <template v-slot:selection="{text, file}">
                      <v-chip small
                              label
                              close
                              @click:close="removeFileFromInput(file)"
                              text-color="white"
                              :color="file.size > 5000000 ? 'red' : 'primary'">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col-->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- TOVÁBBI END -->
        <!-- ÜGYFÉL ADATOK START -->
        <v-col cols="12" lg="6">
          <v-card class="fill-height">
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-account-circle</v-icon>
              <span class="subtitle-1">{{ $t('stepper.customerDetails') }}</span>
              <v-spacer />
              <v-checkbox v-model="isNewCustomer"
                          :label="$t('isNewCustomer')"
                          :true-value="1"
                          :false-value="0"
                          hide-details
                          class="ma-0">
              </v-checkbox>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-6">
              <v-row>
                <template v-if="isNewCustomer === 1">
                  <v-col cols="12">
                    <v-select v-model="worksheet.customer.customerType"
                              :items="selectFieldOptions.customerTypes"
                              item-value="value"
                              item-text="text"
                              :label="$t('inputLabels.customerType')"
                              :hint="$t('inputMessages.requiredField')"
                              persistent-hint
                              filled
                              required>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="worksheet.customer.lastName"
                                  :label="$t('inputLabels.lastName')"
                                  :rules="inputRules.noSpecialCharAndReq"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  counter="30"
                                  filled
                                  required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="worksheet.customer.firstName"
                                  :label="$t('inputLabels.firstName')"
                                  :rules="inputRules.noSpecialCharAndReq"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  counter="30"
                                  filled
                                  required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="worksheet.customer.email"
                                  :label="$t('inputLabels.email')"
                                  :rules="inputRules.emailRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  counter="30"
                                  filled
                                  required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="worksheet.customer.phoneNumber"
                                  :label="$t('inputLabels.phone')"
                                  :rules="inputRules.phoneRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  prefix="+36-"
                                  v-mask="mask"
                                  filled
                                  required>
                    </v-text-field>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="12">
                    <v-autocomplete v-model="selectedCustomerId"
                                    :items="selectFieldOptions.customerList"
                                    :label="$t('lg.customerName')"
                                    item-value="id"
                                    item-text="company_name"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    persistent-hint
                                    filled
                                    @change="onChangeCustomerList(selectedCustomerId)"
                                    required>
                    </v-autocomplete>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- ÜGYFÉL ADATOK END -->
        <!-- CÍM ADATOK START -->
        <v-col cols="12" lg="6">
          <v-card class="fill-height">
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-truck-fast-outline</v-icon>
              <span class="subtitle-1">{{ $t('innerSideNav.addressInformation') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
              <v-row>
                <template v-if="isNewCustomer === 1">
                  <v-col cols="12" class="pb-0">
                    <v-checkbox v-model="worksheet.isStoreDeliveryOut"
                                :label="$t('deliveryInStore')"
                                :true-value="1"
                                :false-value="0"
                                class="mt-0">
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="deliveryInAddressString"
                                  :label="$t('deliveryAddress')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  filled
                                  append-icon="mdi-pencil"
                                  readonly
                                  :disabled="worksheet.isStoreDeliveryOut === 1"
                                  @click="deliveryInAddressModal = true"
                                  @click:append="deliveryInAddressModal = true">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="pb-0" v-if="worksheet.isStoreDeliveryOut === 0">
                    <v-checkbox v-model="billingAddressIsEqualdeliveryInAddress"
                                class="mt-0"
                                :disabled="!(deliveryInAddressForm || deliveryInAddressString.length > 2)">
                      <template slot="label">
                      <span class="body-2">
                        {{ $t('invoicingAddressIsEqualShippingAddress') }}
                      </span>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <v-text-field v-model="billingAddressString"
                                  :label="$t('invoicingAddress')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  filled
                                  append-icon="mdi-pencil"
                                  readonly
                                  :disabled="billingAddressIsEqualdeliveryInAddress"
                                  @click="billingAddressModal = true"
                                  @click:append="billingAddressModal = true">
                    </v-text-field>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="12">
                    <div class="text-caption font-weight-bold">
                      Szállítási cím
                    </div>
                    <div class="mb-2">
                      {{ selectedCustomerDeliveryInAddressString }}
                    </div>
                    <div class="text-caption font-weight-bold">
                      Kiszállítási cím
                    </div>
                    <div class="mb-2">
                      {{ selectedCustomerDeliveryOutAddressString }}
                    </div>
                    <div class="text-caption font-weight-bold">
                      Számlázási cím
                    </div>
                    <div class="mb-2">
                      {{ selectedCustomerBillingAddressString }}
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- CÍM ADATOK END -->
        <!-- KÉPEK START -->
        <v-col cols="12">
          <v-card class="fill-height">
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-file-document</v-icon>
              <span class="subtitle-1">{{ $t('documentUpload') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-file-input v-model="attachment.invoice"
                                :label="$t('inputLabels.invoice')"
                                :rules="inputRules.maxFileSizeOptional"
                                :hint="$t('inputMessages.maxFileSizeRuleOptional')"
                                persistent-hint
                                accept=".pdf,.PDF,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                                counter
                                show-size
                                filled
                                ref="valami">
                    <template v-slot:selection="{text}">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-file-input v-model="attachment.warrantyCard"
                                :label="$t('inputLabels.warrantyTicket')"
                                :rules="inputRules.maxFileSizeOptional"
                                :hint="$t('inputMessages.maxFileSizeRuleOptional')"
                                persistent-hint
                                accept=".pdf,.PDF,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                                counter
                                show-size
                                filled>
                    <template v-slot:selection="{text}">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-file-input v-model="files"
                                :label="$t('inputLabels.otherDocsPics')"
                                :rules="inputRules.maxFileSizeOptional"
                                :hint="$t('inputMessages.maxFileSizeRuleOptional')"
                                persistent-hint
                                multiple
                                accept="image/*, .pdf, .doc, .docx"
                                counter
                                show-size
                                filled
                                ref="fileInput">
                    <template v-slot:selection="{text, file}">
                      <v-chip small
                              label
                              close
                              @click:close="removeFileFromInput(file)"
                              text-color="white"
                              :color="file.size > 5000000 ? 'red' : 'primary'">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- KÉPEK END -->
      </v-row>
    </v-form>
    <!-- MENÜSOR START -->
    <v-row class="pa-3" justify="end">
      <!--v-btn color="primary" class="mr-3" v-if="$route.params.ws !== 'uj-megrendeles'">
        {{ $t('button.edit') }}
      </v-btn-->
      <v-btn color="primary" class="mr-3" @click="sendAppleForm" :disabled="!appleForm">
        {{ $t('button.save') }}
      </v-btn>
    </v-row>
    <!-- MENÜSOR END -->
    <!-- SZÁLLÍTÁSI CÍM MODAL START -->
    <v-dialog v-model="deliveryInAddressModal" max-width="800" persistent>
      <v-card tile>
        <v-card-title class="third">
          <v-icon color="white" class="mr-4">mdi-truck-check-outline</v-icon>
          <span class="white--text">{{ $t('deliveryAddress') }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="deliveryInAddressModal = false" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <v-form v-model="deliveryInAddressForm">
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete v-model="worksheet.deliveryInAddress.postalCode"
                                :items="selectFieldOptions.postalCodes"
                                item-text="address.postalCode"
                                item-value="address.postalCode"
                                :search-input.sync="postalCodeSearch"
                                :label="$t('inputLabels.postalCode')"
                                :rules="inputRules.phoneRules"
                                :hint="$t('inputMessages.requiredField')"
                                :no-data-text="$t('inputMessages.noDataText')"
                                persistent-hint
                                filled
                                maxlength="4"
                                required
                                @change="onPostalCodeSelect"
                                @click:clear="clearAllAddressInputs"
                                clearable>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete v-model="worksheet.deliveryInAddress.city"
                                :items="selectFieldOptions.cities"
                                item-value="address.city"
                                item-text="address.city"
                                :search-input.sync="citySearch"
                                :label="$t('inputLabels.city')"
                                :rules="inputRules.generalRules"
                                :hint="$t('inputMessages.requiredField')"
                                :no-data-text="$t('inputMessages.noDataText')"
                                persistent-hint
                                filled
                                required
                                clearable
                                @click:clear="clearAllAddressInputs">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="8">
                <v-combobox v-model="streetIdeiglenes"
                            :items="selectFieldOptions.streets"
                            item-value="address.street"
                            item-text="address.street"
                            :search-input.sync="streetSearch"
                            :label="$t('inputLabels.publicPlaceName')"
                            :rules="inputRules.generalRules"
                            :hint="$t('inputMessages.requiredField')"
                            :no-data-text="$t('inputMessages.noDataText')"
                            persistent-hint
                            filled
                            required
                            @change="onPublicPlaceSelect"
                            clearable>
                </v-combobox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field v-model="worksheet.deliveryInAddress.streetNumber"
                              :label="$t('inputLabels.streetNumber')"
                              :rules="inputRules.generalRules"
                              :hint="$t('inputMessages.requiredField')"
                              :no-data-text="$t('inputMessages.noDataText')"
                              counter="30"
                              max-length="30"
                              persistent-hint
                              filled
                              required
                              clearable>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea v-model="worksheet.deliveryInAddress.other"
                            :label="$t('infoToDeliver')"
                            :rules="inputRules.noSpecialChar"
                            :hint="$t('inputMessages.optionalField')"
                            persistent-hint
                            clearable
                            filled
                            clear-icon="mdi-close"
                            maxlength="50"
                            counter="50">
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <v-spacer></v-spacer>
          <v-btn text @click="deliveryInAddressModal = false">
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn class="primary" @click="saveDeliveryInAddressData" :disabled="!deliveryInAddressForm">
            {{ $t('button.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- SZÁLLÍTÁSI CÍM MODAL END -->
    <!-- SZÁMLÁZÁSI CÍM MODAL -->
    <v-dialog v-model="billingAddressModal" max-width="800" persistent>
      <v-card tile>
        <v-card-title class="third">
          <v-icon color="white" class="mr-4">mdi-file-account-outline</v-icon>
          <span class="white--text">{{ $t('invoicingAddress') }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="billingAddressModal = false" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <v-form v-model="billingAddressForm">
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-radio-group v-model="worksheet.billingAddress.isCompany"
                               row mandatory class="mt-0" hide-details>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div :class="worksheet.billingAddress.isCompany === 0 ? 'bra' : 'bri'"
                           class="pa-3">
                        <v-radio :value="0">
                          <template slot="label">
                            <span class="text-uppercase br-label">{{ $t('inputLabels.privatePerson') }}</span>
                          </template>
                        </v-radio>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div :class="worksheet.billingAddress.isCompany === 1 ? 'bra' : 'bri'"
                           class="pa-3">
                        <v-radio :value="1">
                          <template slot="label">
                            <span class="text-uppercase br-label">{{ $t('inputLabels.company') }}</span>
                          </template>
                        </v-radio>
                      </div>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="worksheet.billingAddress.billingName"
                              :label="$t('invoicingName')"
                              :rules="inputRules.generalRules"
                              :hint="$t('inputMessages.requiredField')"
                              persistent-hint
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="worksheet.billingAddress.taxNumber"
                              :label="$t('vatNumber')"
                              :rules="worksheet.billingAddress.isCompany === 1 ? inputRules.vatRules : []"
                              :hint="worksheet.billingAddress.isCompany === 1 ? $t('inputMessages.requiredField') : $t('inputMessages.optionalField')"
                              v-mask="taxNumberMaskHU"
                              persistent-hint
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete v-model="worksheet.billingAddress.postalCode"
                                :items="selectFieldOptions.postalCodesInvoice"
                                item-text="address.postalCode"
                                item-value="address.postalCode"
                                :search-input.sync="postalCodeSearchInvoice"
                                :label="$t('inputLabels.postalCode')"
                                :rules="inputRules.phoneRules"
                                :hint="$t('inputMessages.requiredField')"
                                :no-data-text="$t('inputMessages.noDataText')"
                                persistent-hint
                                filled
                                maxlength="4"
                                required
                                @change="onPostalCodeSelectInvoice"
                                @click:clear="clearAllAddressInputsInvoice"
                                clearable>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete v-model="worksheet.billingAddress.city"
                                :items="selectFieldOptions.citiesInvoice"
                                item-value="address.city"
                                item-text="address.city"
                                :search-input.sync="citySearchInvoice"
                                :label="$t('inputLabels.city')"
                                :rules="inputRules.generalRules"
                                :hint="$t('inputMessages.requiredField')"
                                :no-data-text="$t('inputMessages.noDataText')"
                                persistent-hint
                                filled
                                required
                                clearable
                                @click:clear="clearAllAddressInputsInvoice">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="8">
                <v-combobox v-model="streetIdeiglenesInvoice"
                            :items="selectFieldOptions.streetsInvoice"
                            item-value="address.street"
                            item-text="address.street"
                            :search-input.sync="streetSearchInvoice"
                            :label="$t('inputLabels.publicPlaceName')"
                            :rules="inputRules.generalRules"
                            :hint="$t('inputMessages.requiredField')"
                            :no-data-text="$t('inputMessages.noDataText')"
                            persistent-hint
                            filled
                            required
                            @change="onPublicPlaceSelectInvoice"
                            clearable>
                </v-combobox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field v-model="worksheet.billingAddress.streetNumber"
                              :label="$t('inputLabels.streetNumber')"
                              :rules="inputRules.generalRules"
                              :hint="$t('inputMessages.requiredField')"
                              :no-data-text="$t('inputMessages.noDataText')"
                              counter="30"
                              max-length="30"
                              persistent-hint
                              filled
                              required
                              clearable>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea v-model="worksheet.billingAddress.other"
                            :label="$t('infoToDeliver')"
                            :rules="inputRules.noSpecialChar"
                            :hint="$t('inputMessages.optionalField')"
                            persistent-hint
                            clearable
                            filled
                            clear-icon="mdi-close"
                            maxlength="50"
                            counter="50">
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <v-spacer></v-spacer>
          <v-btn text @click="billingAddressModal = false">
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn class="primary" @click="saveBillingAddressData" :disabled="!billingAddressForm">
            {{ $t('button.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- LAR DIALOG START -->
    <loading-and-response-dialog :element-props.sync="loadingAndResponseDialog"
                                 v-on:on-error-click="onErrorClickInLARDialog"
                                 v-on:on-success-click="onSuccussCickInLARDialog"></loading-and-response-dialog>
    <!-- LAR DIALOG END -->
  </v-container>
</template>

<script>
import LoadingAndResponseDialog from 'src/components/dialogs/LoadingAndResponseDialog.vue';
import { HEREGETCITYBYPOSTALCODE, HEREGETPOSTALCODESBYCOUNTRY, HEREGETSTREETBYPOSTALCODE } from 'src/utils/hereAPI';
import { getURA } from 'src/utils/jwtHelper';
import dayjs from 'dayjs';

export default {
  name: 'AppleFormPage',
  components: {
    LoadingAndResponseDialog,
  },
  props: {
    //
  },
  async beforeMount() {
    // if (this.$store.state.authModule.user.profile.customerType === 'EMPLOYEE') {
    if (this.$store.state.authModule.user.profile.customerType === 'EMPLOYEE' && await getURA().split(',').includes('APPLE')) {
      await this.setDates();
      await this.setServiceRequestFormCountry();
      await this.initForm();
      if (this.$route.params.ws === 'uj-megrendeles') {
        this.worksheet.deliveryInAddress = this.$APPLE_STORE_ADDRESS;
        this.saveDeliveryInAddressData();
        this.editable = true;
      } else {

      }
    } else {
      await this.$router.push({name: 'iranyitopult'});
    }
  },
  data() {
    return {
      worksheet: {
        formType: 17,
        processType: 1,
        prevCrmRequestId: null,
        prevGsmWorkSheetId: null,
        sourceSystem: null,
        externalId: null,
        serviceRequest: {
          partnerWorksheetNumber: null,
          dateOfDelivery: null,
          repairCostLimit: null,
          prevCrmRequestId: null,
          prevGsmWorkSheetId: null,
          availableServiceId: null,
          brand: {},
          b2bRefNo: null,
          productGroup: {},
          productTypeFull: null,
          productType: {},
          uniqueIdentifierIn1: '',
          uniqueIdentifierIn2: '',
          uniqueIdentifierIn3: '',
          warrantyType: {},
          dateOfPurchase: null,
          errorDescription: '',
          networkLock: {},
          mobilProvider: {},
          insuranceNumber: '',
          rmaNumber: '',
          accessories: null,
          paymentMethod: null,
          deductible: null,
          isLoan: null,
          purchasePrice: null,
          otherComment: '',
        },
        customer: {
          id: null,
          customerType: 'APPLESTORE',
          crmNamePrefixId: null,
          firstName: '',
          middleName: null,
          lastName: '',
          email: '',
          phoneNumber: '',
          password: null,
        },
        deliveryInAddress: {
          id: null,
          isCompany: 0,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
        },
        billingAddress: {
          id: null,
          isCompany: 0,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
        },
        deliveryOutAddress: {},
        gdpr: false,
        termsAndCond: false,
        generalInfos: false,
        accountRegistration: null,
        isAutomaticQuotationAccepting: null,
        isVipService: null,
        isAcceptanceOfUsedParts: null,
        calculatedRepairPrice: null,
        calcDataId: null,
        isStoreDeliveryOut: 1,
        physicalDamages: 'Normál állapot, apró használati nyomok, karcok, sérülések',
        aestheticFaults: 'Normál állapot, apró használati nyomok, karcok, sérülések',
      },
      additionalDamages: {
        crmRequestId: null,
        additionalDamageData: {
          physicalDamage: {
            physicalDamageTypeId: null,
            physicalDamageTypeName: null,
            physicalDamageTypeDescription: 'Normál állapot, apró használati nyomok, karcok, sérülések',
          },
          aestheticFault: {
            aestheticFaultTypeId: null,
            aestheticFaultTypeName: null,
            aestheticFaultTypeDescription: 'Normál állapot, apró használati nyomok, karcok, sérülések',
          },
        },
      },
      files: [],
      appleForm: false,
      mask: '##-###-####',
      priceMask: '##########',
      imeiMask: '###############',
      dateTextMask: '####-##-##',
      taxNumberMaskHU: '########-#-##',
      maxDate: null,
      typeSearch: '',
      attachment: {
        invoice: null,
        warrantyCard: null,
      },
      editable: false,
      selectedCustomerId: null,
      isNewCustomer: 1,
      /**
       * SZÁLLÍTÁSI CÍMHEZ
       */
      deliveryInAddressString: '',
      deliveryInAddressForm: false,
      deliveryInAddressModal: false,
      countrySearch: '',
      countrySearchIsLoading: false,
      postalCodeSearch: '',
      postalCodeSearchIsLoading: false,
      citySearch: '',
      citySearchIsLoading: false,
      streetSearch: '',
      streetSearchIsLoading: false,
      streetIdeiglenes: '',
      deliveryInAddressBackup: null,
      /**
       * SZÁMLÁZÁSI CÍMHEZ
       */
      billingAddressString: '',
      billingAddressForm: false,
      billingAddressModal: false,
      postalCodeSearchInvoice: '',
      postalCodeSearchIsLoadingInvoice: false,
      citySearchInvoice: '',
      citySearchIsLoadingInvoice: false,
      streetSearchInvoice: '',
      streetSearchIsLoadingInvoice: false,
      streetIdeiglenesInvoice: '',
      billingAddressBackup: null,
      // számlázási cím megegyezik a szállítási címmel
      billingAddressIsEqualdeliveryInAddress: false,
      loadingAndResponseDialog: {
        isVisible: false,
        stepper: 1,
        loadingText: 'loadingMessages.INPROGRESS_CONTACT_FORM',
        successText: 'SUCCESS_MESSAGES.APPLEWORKSHEET_SAVE',
        errorText: 'ERROR_MESSAGES.APPLEWORKSHEET_SAVE',
        successBtnText: 'button.ok',
        errorBtnText: 'button.ok',
      },
      selectFieldOptions: {
        brands: [],
        productGroups: [],
        productTypes: [],
        warrantyType: [
          {
            id: 1,
            name: 'Garanciális javítás',
          },
          {
            id: 2,
            name: 'Fizetős javítás',
          },
        ],
        countries: [
          { label: 'Magyarország', countryCode: 'HUN' },
        ],
        countriesEN: [
          { label: 'Hungary', countryCode: 'HUN' },
        ],
        countriesDE: [
          { label: 'Ungarn', countryCode: 'HUN' },
        ],
        postalCodes: [
          { header: this.$t('inputMessages.startTypingPostalCode') },
        ],
        cities: [
          { header: this.$t('inputMessages.startTypingCity') },
        ],
        streets: [
          { header: this.$t('inputMessages.startTypingStreet') },
        ],
        customerShippingAddresses: [],
        customerBillingAddresses: [],
        // számlázási címhez selectek
        postalCodesInvoice: [
          { header: this.$t('inputMessages.startTypingPostalCode') },
        ],
        citiesInvoice: [
          { header: this.$t('inputMessages.startTypingCity') },
        ],
        streetsInvoice: [
          { header: this.$t('inputMessages.startTypingStreet') },
        ],
        physicalDamages: [],
        aestheticFaults: [],
        customerTypes: [
          { value: 'APPLESTORE', text: 'iStuff' },
          { value: 'APPLESTORE-B2B', text: 'iStuff B2B' },
        ],
        customerList: [],
      },
      inputRules: {
        acceptInformation: [v => !!v || this.$t('inputMessages.acceptInformation')],
        generalRules: [v => !!v || this.$t('inputMessages.requiredField')],
        emailRules: [
          v => /.+@.+\..+/.test(v) || this.$t('inputMessages.emailMatch'),
          v => !(/\s/.test(v)) || this.$t('inputMessages.emailNoSpace'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        phoneRules: [
          v => /^[0-9\-]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        gdprRules: [
          v => !!v || this.$t('inputMessages.requiredPrivacy'),
        ],
        termAndCondRules: [
          v => !!v || this.$t('inputMessages.requiredTermsAndCond'),
        ],
        maxFileSize: [
          v => !v || v.size < 5000000 || this.$t('inputMessages.maxFileSizeRule'),
        ],
        maxFileSizeOptional: [
          v => this.checkMaxFileSizeOK(v) || this.$t('inputMessages.maxFileSizeRule'),
        ],
        requiredFile: [
          v => !v || v.size < 5000000 || this.$t('inputMessages.maxFileSizeRule'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        min8: [
          v => v.length >= 8 || this.$t('inputMessages.passwordRuleMin8'),
          v => !!v || this.$t('inputMessages.requiredField')
        ],
        imeiRule: [
          v => !!v || this.$t('inputMessages.requiredField'),
          v => /^[0-9]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
          v => (v && v.length === 15) || this.$t('inputMessages.minLenght15'),
        ],
        onlyNumbers: [
          v => !!v || this.$t('inputMessages.optionalField'),
          v => /^[0-9]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
        ],
        snRule: [
          v => !!v || this.$t('inputMessages.requiredField'),
          v => /^[a-zA-Z0-9\-\/\\]*$/.test(v) || this.$t('inputMessages.snRules'),
          v => (v && v.length < 23) || this.$t('inputMessages.minLenght15'),
        ],
        selectRules: [
          v => !!v || this.$t('inputMessages.requiredField'),
          v => ( v && v.id > 0) || this.$t('inputMessages.requiredField'),
        ],
        purchaseDateRule: [
          v => !!v || this.$t('inputMessages.purchaseDateReq'),
          v => this.purchaseDateIsOK(v) || `${this.$t('inputMessages.purchaseDateMax')} ${this.maxDate}!`,
        ],
        vatRules: [
          (v) => !!v || this.$t('inputMessages.requiredField'),
          (v) => (v && v.length === 13) || this.$t('inputMessages.requiredField'),
        ],
        noSpecialCharAndReq: [
          (v) => !!v || this.$t('inputMessages.requiredField'),
          (v) => /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.requiredFieldNoSpecialChar'),
        ],
        noSpecialChar: [
          (v) => {
            return /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.noSpecialChar')
          },
        ],

      },
    };
  },
  methods: {
    async initForm() {
      const response = await this.$store.dispatch('showForm', 'AppleMunkalapForm');
      if (response.status === 200) {
        this.selectFieldOptions.warrantyType = JSON.parse(JSON.stringify(response.data.warrantyType));
        this.worksheet.serviceRequest.networkLock = JSON.parse(JSON.stringify(response.data.networkLock[0]));
        this.selectFieldOptions.brands = JSON.parse(JSON.stringify(response.data.brand));
        this.worksheet.serviceRequest.brand = JSON.parse(JSON.stringify(response.data.brand[0]));
        this.selectFieldOptions.productGroups = await this.callShowProductGroupOfBrand(this.worksheet.serviceRequest.brand);
        await this.callShowPaymentMethodOfCustomerType();
      }
      await this.loadCustomerList();
    },
    async ProductGroupConverter(arrayToConvert) {
      let input = arrayToConvert;
      for (let i = 0; i < input.length; i++) {
        switch (input[i].id) {
          case 1:
            input[i].displayName = 'iPhone';
            break;
          case 16:
            input[i].displayName = 'Apple TV';
            break;
          case 6:
            input[i].displayName = 'Mac';
            break;
          case 5:
            input[i].displayName = 'Apple Watch';
            break;
          case 4:
            input[i].displayName = 'Apple Accessories';
            break;
          case 2:
            input[i].displayName = 'iPad';
            break;
        }
      }
      return input;
    },
    checkMaxFileSizeOK(files) {
      if (files) {
        if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            if (files[i].size > 5000000) {
              return false;
            }
          }
        }
      }
      return true;
    },
    removeFileFromInput(file) {
      this.files.splice(this.files.indexOf(file), 1);
      this.files = [...this.files];
    },
    async callAppleWorksheetById(wsid) {
      const response = await this.$store.dispatch('GetAppleWorksheetById', { id: wsid });
    },
    /**
     *
     * Űrlap beküldése
     *
     */
    async sendAppleForm() {
      this.openLARDialog();
      // Ha meglévő ügyfelet választunk
      // akkor nem kell a címekkel varázsolgatni
      if (this.isNewCustomer === 1) {
        this.worksheet.deliveryOutAddress = JSON.parse(JSON.stringify(this.worksheet.deliveryInAddress));
        if (this.worksheet.isStoreDeliveryOut === 1) {
          this.worksheet.deliveryInAddress = JSON.parse(JSON.stringify(this.$APPLE_STORE_ADDRESS));
        }
      }
      switch (this.worksheet.serviceRequest.warrantyType.id) {
        case 1:
          if (this.isNewCustomer === 1) {
            if (this.worksheet.isStoreDeliveryOut === 1) {
              this.worksheet.serviceRequest.availableServiceId = 92;
            } else {
              this.worksheet.serviceRequest.availableServiceId = 79;
            }
          } else {
            this.worksheet.serviceRequest.availableServiceId = 132;
          }
          break;
        case 2:
          if (this.isNewCustomer === 1) {
            if (this.worksheet.isStoreDeliveryOut === 1) {
              this.worksheet.serviceRequest.availableServiceId = 93;
            } else {
              this.worksheet.serviceRequest.availableServiceId = 80;
            }
          } else {
            this.worksheet.serviceRequest.availableServiceId = 133;
          }
          break;
        case 9:
          if (this.isNewCustomer === 1) {
            if (this.worksheet.isStoreDeliveryOut === 1) {
              this.worksheet.serviceRequest.availableServiceId = 94;
            } else {
              this.worksheet.serviceRequest.availableServiceId = 81;
            }
          } else {
            this.worksheet.serviceRequest.availableServiceId = 134;
          }
          break;
      }
      this.worksheet.serviceRequest.mobilProvider = JSON.parse(JSON.stringify(this.worksheet.serviceRequest.networkLock));
      if (this.billingAddressIsEqualdeliveryInAddress) {
        this.worksheet.billingAddress.billingName = `${this.worksheet.customer.lastName} ${this.worksheet.customer.firstName}`;
      }
      // Purchase Price ellenőrzése
      if (this.worksheet.serviceRequest.purchasePrice === '') {
        this.worksheet.serviceRequest.purchasePrice = null;
      }
      if (this.worksheet.serviceRequest.purchasePrice) {
        if (this.worksheet.serviceRequest.purchasePrice.length < 1) {
          this.worksheet.serviceRequest.purchasePrice = null;
        }
      }
      if (this.worksheet.calculatedRepairPrice === '') {
        this.worksheet.calculatedRepairPrice = null;
      }
      if (this.worksheet.calculatedRepairPrice) {
        if (this.worksheet.calculatedRepairPrice.length < 1) {
          this.worksheet.calculatedRepairPrice = null;
        } else {
          this.worksheet.isAutomaticQuotationAccepting = 1;
        }
      }
      const response = await this.$store.dispatch('sendMobilfutarRequest',
        {form: this.worksheet, attachment: this.attachment, appleDocs: this.files});
      if (response.status === 200) {
        const shopInDoc = await this.getAppleShopDocument(response.data.id, 'shopIn');
        if (shopInDoc) {
          this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.APPLEWORKSHEET_SAVE';
        } else {
          this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.APPLEWORKSHEET_SAVE_NO_SHOPIN';
        }
        this.changeLARDialogContentToSuccess();
      } else if (response.status === 201) {
        this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.APPLEWORKSHEET_SAVE';
        this.changeLARDialogContentToSuccess();
      } else if (response.status === 403) {
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.USER_EXISTS';
        this.changeLARDialogContentToError();
      } else if (response.status === 422) {
        this.loadingAndResponseDialog.errorText = response.message;
        this.changeLARDialogContentToError();
      } else if (response.status === 405) {
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.APPLEWORKSHEET_SAVE';
        this.changeLARDialogContentToError();
      } else {
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.APPLEWORKSHEET_SAVE';
        this.changeLARDialogContentToError();
      }
    },
    async setDates() {
      const now = new Date();
      // const date = dayjs(now).subtract(3, 'day');
      this.maxDate = now.toISOString().substr(0, 10);
      if (this.$route.params.ws === 'uj-megrendeles') {
        this.worksheet.serviceRequest.dateOfDelivery = now.toISOString().substr(0, 10);
      }
    },
    async setServiceRequestFormCountry() {
      if (this.$i18n.locale === 'hu-HU') {
        this.worksheet.deliveryInAddress.country = this.selectFieldOptions.countries[0];
        this.worksheet.billingAddress.country = this.selectFieldOptions.countries[0];
      } else if (this.$i18n.locale === 'de-DE') {
        this.worksheet.deliveryInAddress.country = this.selectFieldOptions.countriesDE[0];
        this.worksheet.billingAddress.country = this.selectFieldOptions.countriesDE[0];
      } else {
        this.worksheet.deliveryInAddress.country = this.selectFieldOptions.countriesEN[0];
        this.worksheet.billingAddress.country = this.selectFieldOptions.countriesEN[0];
      }
    },
    /**
     * ÉRTÉKESÍTETT TARTOZÉKOK
    */
    addAccessory() {
      this.worksheet.serviceRequest.extraAccessories.push({ name: '', amount: null, });
      window.scrollTo(0, document.body.scrollHeight);
    },
    deleteAccessory(index) {
      // const ind = this.worksheet.serviceRequest.extraAccessories.findIndex(item);
      this.worksheet.serviceRequest.extraAccessories.splice(index, 1);
    },
    /**
     * SZÁLLÍTÁSI CÍM MEGADÁSÁHOZ FGV-EK
     */
    async getPostalCodesByCountry(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.worksheet.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodes = response.data;
        this.postalCodeSearchIsLoading = false;
        this.selectFieldOptions.postalCodes.unshift({ header: this.$t('inputMessages.startTypingPostalCode') });
      }
    },
    async getCityByPostalCode() {
      let postalcode = 0;
      if (this.worksheet.deliveryInAddress.postalCode && this.worksheet.deliveryInAddress.postalCode.address) {
        postalcode = this.worksheet.deliveryInAddress.postalCode.address.postalCode;
        this.worksheet.deliveryInAddress.postalCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.worksheet.deliveryInAddress.postalCode, this.worksheet.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.cities = response.data;
        this.citySearchIsLoading = false;
        this.selectFieldOptions.cities.unshift({ header: this.$t('inputMessages.startTypingCity') });
      }
    },
    async getStreetByPostalCode(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.worksheet.deliveryInAddress.postalCode, this.worksheet.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streets = response.data;
        this.streetSearchIsLoading = false;
        this.selectFieldOptions.streets.unshift({ header: this.$t('inputMessages.startTypingStreet') });
      }
    },
    async onPostalCodeSelect() {
      await this.getCityByPostalCode();
      if (this.selectFieldOptions.cities.length === 2) {
        this.worksheet.deliveryInAddress.city = this.selectFieldOptions.cities[1].address.city;
        this.citySearch = this.selectFieldOptions.cities[1].address.city;
      }
      this.worksheet.deliveryInAddress.publicPlaceName = '';
      this.worksheet.deliveryInAddress.publicPlaceType = '';
      this.worksheet.deliveryInAddress.streetNumber = '';
      this.streetIdeiglenes = '';
      this.streetSearch = null;
    },
    onPublicPlaceSelect() {
      // console.log('onPublicPlaceSelect');
      let street = '';
      let type = '';
      if (this.streetIdeiglenes && this.streetIdeiglenes.length > 0) {
        // console.log('1');
        street = this.streetIdeiglenes.substring(0, this.streetIdeiglenes.lastIndexOf(' '));
        type = this.streetIdeiglenes.substring(this.streetIdeiglenes.lastIndexOf(' '), this.streetIdeiglenes.length + 1).trim();
        if (this.streetIdeiglenes.lastIndexOf(' ') > 0) {
          // console.log('1a');
          this.worksheet.deliveryInAddress.publicPlaceName = street;
          this.worksheet.deliveryInAddress.publicPlaceType = type;
        } else {
          // console.log('1b');
          this.worksheet.deliveryInAddress.publicPlaceName = this.streetIdeiglenes;
          type = this.selectFieldOptions.streets[1].address.street.substring(this.selectFieldOptions.streets[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streets[1].address.street.length + 1).trim();
          this.worksheet.deliveryInAddress.publicPlaceType = type;
        }
      } else if (this.streetIdeiglenes && this.streetIdeiglenes.address) {
        // console.log('2');
        street = this.streetIdeiglenes.address.street.substring(0, this.streetIdeiglenes.address.street.lastIndexOf(' '));
        type = this.streetIdeiglenes.address.street.substring(this.streetIdeiglenes.address.street.lastIndexOf(' '), this.streetIdeiglenes.address.street.length + 1).trim();
        if (this.streetIdeiglenes.address.street.lastIndexOf(' ') > 0) {
          // console.log('2a');
          this.worksheet.deliveryInAddress.publicPlaceName = street;
          this.worksheet.deliveryInAddress.publicPlaceType = type;
        } else {
          console.log('2b');
        }
      }
      //
      //
    },
    preventEnter() {
      //
    },
    clearAllAddressInputs() {
      this.selectFieldOptions.postalCodes = [
        { header: this.$t('inputMessages.startTypingPostalCode') },
      ];
      this.selectFieldOptions.cities = [
        { header: this.$t('inputMessages.startTypingCity') },
      ];
      this.selectFieldOptions.streets = [
        { header: this.$t('inputMessages.startTypingStreet') },
      ];
      this.postalCodeSearch = '';
      this.postalCodeSearchIsLoading = false;
      this.citySearch = '';
      this.citySearchIsLoading = false;
      this.streetSearch = '';
      this.streetSearchIsLoading = false;
      this.streetIdeiglenes = '';
      this.worksheet.deliveryInAddress.postalCode = '';
      this.worksheet.deliveryInAddress.city = '';
      this.worksheet.deliveryInAddress.publicPlaceType = '';
      this.worksheet.deliveryInAddress.publicPlaceName = '';
      this.worksheet.deliveryInAddress.streetNumber = '';
    },
    saveDeliveryInAddressData() {
      this.deliveryInAddressString = this.worksheet.deliveryInAddress.postalCode + ' '
        + this.worksheet.deliveryInAddress.city + ', '
        + this.worksheet.deliveryInAddress.publicPlaceName + ' '
        + this.worksheet.deliveryInAddress.publicPlaceType + ' '
        + this.worksheet.deliveryInAddress.streetNumber;
      this.deliveryInAddressModal = false;
    },
    /***
     * SZÁMLÁZÁSI CÍM MEGADÁSÁHOZ A FGV-EK
     */
    async getPostalCodesByCountryInvoice(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.worksheet.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodesInvoice = response.data;
        this.postalCodeSearchIsLoadingInvoice = false;
        this.selectFieldOptions.postalCodesInvoice.unshift({ header: this.$t('inputMessages.startTypingPostalCode') });
      }
    },
    async getCityByPostalCodeInvoice() {
      let postalcode = 0;
      if (this.worksheet.billingAddress.postalCode && this.worksheet.billingAddress.postalCode.address) {
        postalcode = this.worksheet.billingAddress.postalCode.address.postalCode;
        this.worksheet.billingAddress.postalCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.worksheet.billingAddress.postalCode, this.worksheet.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.citiesInvoice = response.data;
        this.citySearchIsLoadingInvoice = false;
        this.selectFieldOptions.citiesInvoice.unshift({ header: this.$t('inputMessages.startTypingCity') });
      }
    },
    async getStreetByPostalCodeInvoice(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.worksheet.billingAddress.postalCode, this.worksheet.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streetsInvoice = response.data;
        this.streetSearchIsLoadingInvoice = false;
        this.selectFieldOptions.streetsInvoice.unshift({ header: this.$t('inputMessages.startTypingStreet') });
      }
    },
    async onPostalCodeSelectInvoice() {
      await this.getCityByPostalCodeInvoice();
      if (this.selectFieldOptions.citiesInvoice.length === 2) {
        this.worksheet.billingAddress.city = this.selectFieldOptions.citiesInvoice[1].address.city;
        this.citySearchInvoice = this.selectFieldOptions.citiesInvoice[1].address.city;
      }
      this.worksheet.billingAddress.publicPlaceName = '';
      this.worksheet.billingAddress.publicPlaceType = '';
      this.worksheet.billingAddress.streetNumber = '';
      this.streetIdeiglenesInvoice = '';
      this.streetSearchInvoice = null;
    },
    onPublicPlaceSelectInvoice() {
      let street = '';
      let type = '';
      if (this.streetIdeiglenesInvoice && this.streetIdeiglenesInvoice.length > 0) {
        street = this.streetIdeiglenesInvoice.substring(0, this.streetIdeiglenesInvoice.lastIndexOf(' '));
        type = this.streetIdeiglenesInvoice.substring(this.streetIdeiglenesInvoice.lastIndexOf(' '), this.streetIdeiglenesInvoice.length + 1).trim();
        if (this.streetIdeiglenesInvoice.lastIndexOf(' ') > 0) {
          // console.log('3a');
          this.worksheet.billingAddress.publicPlaceName = street;
          this.worksheet.billingAddress.publicPlaceType = type;
        } else {
          // console.log('3b');
          this.worksheet.billingAddress.publicPlaceName = this.streetIdeiglenesInvoice;
          type = this.selectFieldOptions.streetsInvoice[1].address.street.substring(this.selectFieldOptions.streetsInvoice[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streetsInvoice[1].address.street.length + 1).trim();
          this.worksheet.billingAddress.publicPlaceType = type;
        }
      } else if (this.streetIdeiglenesInvoice && this.streetIdeiglenesInvoice.address) {
        street = this.streetIdeiglenesInvoice.address.street.substring(0, this.streetIdeiglenesInvoice.address.street.lastIndexOf(' '));
        type = this.streetIdeiglenesInvoice.address.street.substring(this.streetIdeiglenesInvoice.address.street.lastIndexOf(' '), this.streetIdeiglenesInvoice.address.street.length + 1).trim();
        if (this.streetIdeiglenesInvoice.address.street.lastIndexOf(' ') > 0) {
          // console.log('4a');
          this.worksheet.billingAddress.publicPlaceName = street;
          this.worksheet.billingAddress.publicPlaceType = type;
        } else {
          console.log('4b');
        }
      }
    },
    clearAllAddressInputsInvoice() {
      this.selectFieldOptions.postalCodesInvoice = [
        { header: this.$t('inputMessages.startTypingPostalCode') },
      ];
      this.selectFieldOptions.citiesInvoice = [
        { header: this.$t('inputMessages.startTypingCity') },
      ];
      this.selectFieldOptions.streetsInvoice = [
        { header: this.$t('inputMessages.startTypingStreet') },
      ];
      this.postalCodeSearchInvoice = '';
      this.postalCodeSearchIsLoadingInvoice = false;
      this.citySearchInvoice = '';
      this.citySearchIsLoadingInvoice = false;
      this.streetSearchInvoice = '';
      this.streetSearchIsLoadingInvoice = false;
      this.streetIdeiglenesInvoice = '';
      this.worksheet.billingAddress.postalCode = '';
      this.worksheet.billingAddress.city = '';
      this.worksheet.billingAddress.publicPlaceType = '';
      this.worksheet.billingAddress.publicPlaceName = '';
      this.worksheet.billingAddress.streetNumber = '';
    },
    saveBillingAddressData() {
      this.billingAddressString = this.worksheet.billingAddress.postalCode + ' '
        + this.worksheet.billingAddress.city + ', '
        + this.worksheet.billingAddress.publicPlaceName + ' '
        + this.worksheet.billingAddress.publicPlaceType + ' '
        + this.worksheet.billingAddress.streetNumber;
      this.billingAddressModal = false;
    },
    openBillingAddressModal() {
      // a meglévő szállítási címet kimentjük egy ideiglenes változóba,
      // hogy ha a user a Mégsem gombra kattint, akkor megmaradjon az előzőleg beírt cím
      this.billingAddressBackup = this.worksheet.billingAddress;
      this.billingAddressModal = true;
    },
    closeBillingAddressModalWithoutSave() {
      // Mégsem gombra kattintva visszaszerezzük az előzőleg beírt címet
      this.worksheet.billingAddress = this.billingAddressBackup;
      this.billingAddressModal = false;
      this.billingAddressBackup = null;
    },
    purchaseDateIsOK(date) {
      const inputDate = dayjs(date);
      const maxD = dayjs(this.maxDate);
      const max = dayjs(maxD).add(1, 'day');
      if (date) {
        if (date.length === 10) {
          if (new Date(date).getDate()) {
            return inputDate.isBefore(dayjs(max));
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    /**
     *
     * Loading and response dialog
     *
     */
    openLARDialog() {
      this.loadingAndResponseDialog.stepper = 1;
      this.loadingAndResponseDialog.isVisible = true;
    },
    closeLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
    },
    changeLARDialogContentToSuccess() {
      this.loadingAndResponseDialog.stepper = 2;
    },
    changeLARDialogContentToError(errorMessage) {
      if (errorMessage) {
        this.loadingAndResponseDialog.errorText = `ERROR_MESSAGES.${errorMessage}`;
      }
      this.loadingAndResponseDialog.stepper = 3;
    },
    onSuccussCickInLARDialog() {
      this.closeLARDialog();
      this.$router.push({ name: 'apple-munkalap' });
    },
    onErrorClickInLARDialog() {
      this.closeLARDialog();
    },
    /*
     * TERMÉK CSOPORT LEKÉRDEZÉSE GYÁRTÓ ALAPJÁN
     */
    async callShowProductGroupOfBrand(brand) {
      // const resp = await this.$store.dispatch('showProductGroupOfBrand', { brandId: brand.id, formTypeId: this.$FORM_APPLEMUNKALAP.id });
      const resp = await this.$store.dispatch('showProductGroupOfBrand', brand.id);
      // console.log('resp: ', resp);
      const conv = await this.ProductGroupConverter(resp.data);
      // this.selectFieldOptions.productGroups = resp.data.sort((a, b) => ((a.name > b.name) ? 1 : -1));
      // return resp.data.sort((a, b) => ((a.name > b.name) ? 1 : -1));
      return conv.sort((a, b) => ((a.displayName > b.displayName) ? 1 : -1));
    },
    /*
     * TERMÉK TÍPUS LEKÉRDEZÉSE GYÁRTÓ ÉS TERMÉK TÍPUS ALAPJÁN
     */
    async callShowProductTypeOfBrand() {
      // console.log('callShowProductTypeOfBrand start...');
      const response = await this.$store.dispatch('showProductTypeOfBrand',
        { brandId: this.worksheet.serviceRequest.brand.id, productGroupId: this.worksheet.serviceRequest.productGroup.id });
      // console.log('callShowProductTypeOfBrand: ', response);
      if (response.status === 200) {
        response.data.sort((a, b) => ((a.productTypeShoppingName > b.productTypeShoppingName) ? 1 : -1));
        // ha APPLE brand.id = 2, akkor nem kell
        const benne = this.$NoDisplayNameBrands.includes(this.worksheet.serviceRequest.brand.id);
        if (benne) {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].displayName = `${response.data[i].productTypeShoppingName}`;
          }
        } else {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].displayName = `${response.data[i].productTypeShoppingName} - ${response.data[i].gsmTipusNev}`;
          }
        }
        this.selectFieldOptions.productTypes = response.data;
      }
    },
    /*
     * USER-TYPE-HOZ TARTOZÓ PAYMENT-METHODOK LEKÉRDEZÉSE
     */
    async callShowPaymentMethodOfCustomerType() {
      const response = await this.$store.dispatch('showPaymentMethodOfCustomerType', this.worksheet.customer.customerType);
      if (response.status === 200) {
        this.worksheet.serviceRequest.paymentMethod = response.data[0];
      }
    },
    async getAppleShopDocument(id, docType) {
      const response = await this.$store.dispatch('DownloadAppleShopDocuments', { id: id, docType: docType });
      return response;
    },
    async randomImei(type) {
      const randomIdentifier = await this.$store.dispatch('randomImeiGenerator');
      // console.log(`%crandomIdentifier: ${randomIdentifier} - hossz: ${randomIdentifier.toString().length}`, 'background-color: #00cec9; color: #ffffff; font-size: 12px;');
      if (type === 1) {
        this.worksheet.serviceRequest.uniqueIdentifierIn1 = randomIdentifier;
      } else if (type === 2) {
        this.worksheet.serviceRequest.uniqueIdentifierIn2 = randomIdentifier;
      } else if (type === 3) {
        this.worksheet.serviceRequest.uniqueIdentifierIn3 = randomIdentifier;
      }
    },
    async loadCustomerList() {
      const response = await this.$store.dispatch('GetReportListById',
        {
          reportHeaderId: 'CUSTOMERLIST',
          queryParams: {
            current_page: 1,
            page_size: 200,
            customer_type_id: 39,
          }
        });
      console.log('loadCustomerList:', response);
      if (response.status === 200) {
        this.selectFieldOptions.customerList = response.data.listRows.filter((item) => item.external_id);
        console.log('filteredList: ', this.selectFieldOptions.customerList);
      }
    },
    async onChangeCustomerList(customerId) {
      await this.initCustomerDatas(customerId);
    },
    async initCustomerDatas(customerId) {
      const customer = await this.$store.dispatch('GetCustomerDataByCustomerId', customerId);
      if (customer === this.$HTTP_OK) {
        this.worksheet.customer = this.$store.getters.getCustomerGeneralDataToPickupReturnRequest;
      }
      const adds = await this.$store.dispatch('GetCustomerAddresses', customerId);
      console.log(adds);
      if (adds === this.$HTTP_OK) {
        this.worksheet.billingAddress = this.$store.getters.getCustomerAddressFromStoreByAddressTypeId(2);
        this.worksheet.deliveryInAddress = this.$store.getters.getCustomerAddressFromStoreByAddressTypeId(1);
        this.worksheet.deliveryOutAddress = this.$store.getters.getCustomerAddressFromStoreByAddressTypeId(3);
      }
      this.worksheet.processType = 5;
    },
  },
  watch: {
    postalCodeSearch(value) {
      this.postalCodeSearchIsLoading = true;
      this.getPostalCodesByCountry(value);
    },
    streetSearch(value) {
      this.streetSearchIsLoading = true;
      this.getStreetByPostalCode(value);
    },
    postalCodeSearchInvoice(value) {
      this.postalCodeSearchIsLoading = true;
      this.getPostalCodesByCountryInvoice(value);
    },
    streetSearchInvoice(value) {
      this.streetSearchIsLoading = true;
      this.getStreetByPostalCodeInvoice(value);
    },
    billingAddressIsEqualdeliveryInAddress(value) {
      if (value) {
        this.worksheet.billingAddress.postalCode = this.worksheet.deliveryInAddress.postalCode;
        this.worksheet.billingAddress.city = this.worksheet.deliveryInAddress.city;
        this.worksheet.billingAddress.publicPlaceName = this.worksheet.deliveryInAddress.publicPlaceName;
        this.worksheet.billingAddress.publicPlaceType = this.worksheet.deliveryInAddress.publicPlaceType;
        this.worksheet.billingAddress.streetNumber = this.worksheet.deliveryInAddress.streetNumber;
        this.worksheet.billingAddress.other = this.worksheet.deliveryInAddress.other;
        this.saveBillingAddressData();
      } else {
        this.clearAllAddressInputsInvoice();
        this.worksheet.billingAddress.billingName = '';
        this.worksheet.billingAddress.taxNumber = '';
        this.worksheet.billingAddress.other = '';
        this.billingAddressString = '';
      }
    },
    'worksheet.serviceRequest.productGroup': function (newVal) {
      // console.log('newVal: ', newVal);
      this.worksheet.serviceRequest.uniqueIdentifierIn1 = null;
      this.worksheet.serviceRequest.uniqueIdentifierIn2 = null;
      this.worksheet.serviceRequest.uniqueIdentifierIn3 = null;
      this.worksheet.serviceRequest.productTypeFull = null;
      this.selectFieldOptions.productTypes = [];
      // this.selectedProductGroup = this.worksheet.serviceRequest.productGroup.find(item => item.productGroup.id === newVal.id);
      if (newVal.id) {
        this.callShowProductTypeOfBrand();
      }
    },
    'worksheet.serviceRequest.productTypeFull': function (newVal) {
      this.worksheet.serviceRequest.productType = {};
      this.typeSearch = '';
      if (newVal) {
        this.worksheet.serviceRequest.productType.id = newVal.gsmTipusId;
        this.worksheet.serviceRequest.productType.name = newVal.productTypeShoppingName;
      }
    },
    'worksheet.isStoreDeliveryOut': function (value) {
      if (value === 1) {
        this.worksheet.deliveryInAddress = JSON.parse(JSON.stringify(this.$APPLE_STORE_ADDRESS));
        this.saveDeliveryInAddressData();
      } else {
        this.worksheet.deliveryInAddress = JSON.parse(JSON.stringify(this.$ADDRESS_TEMPLATE));
        this.deliveryInAddressString = '';
      }
    },
    isNewCustomer(value) {
      if (value === 1) {
        this.selectedCustomerId = null;
        this.worksheet.customer = {
          id: null,
          customerType: 'APPLESTORE',
          crmNamePrefixId: null,
          firstName: '',
          middleName: null,
          lastName: '',
          email: '',
          phoneNumber: '',
          password: null,
        };
        this.worksheet.deliveryInAddress = JSON.parse(JSON.stringify(this.$ADDRESS_TEMPLATE));
        this.worksheet.deliveryOutAddress = {};
        this.worksheet.billingAddress = JSON.parse(JSON.stringify(this.$ADDRESS_TEMPLATE));
        this.worksheet.processType = 1;
      }
    },
  },
  computed: {
    selectedCustomerDeliveryInAddressString() {
      if (this.isNewCustomer === 0 && this.worksheet.customer.id) {
        return this.worksheet.deliveryInAddress.postalCode + ' '
          + this.worksheet.deliveryInAddress.city + ', '
          + this.worksheet.deliveryInAddress.publicPlaceName + ' '
          + this.worksheet.deliveryInAddress.publicPlaceType + ' '
          + this.worksheet.deliveryInAddress.streetNumber;
      }
      return ' ';
    },
    selectedCustomerDeliveryOutAddressString() {
      if (this.isNewCustomer === 0 && this.worksheet.customer.id) {
        return this.worksheet.deliveryOutAddress.postalCode + ' '
          + this.worksheet.deliveryOutAddress.city + ', '
          + this.worksheet.deliveryOutAddress.publicPlaceName + ' '
          + this.worksheet.deliveryOutAddress.publicPlaceType + ' '
          + this.worksheet.deliveryOutAddress.streetNumber;
      }
      return ' ';
    },
    selectedCustomerBillingAddressString() {
      if (this.isNewCustomer === 0 && this.worksheet.customer.id) {
        return this.worksheet.billingAddress.postalCode + ' '
          + this.worksheet.billingAddress.city + ', '
          + this.worksheet.billingAddress.publicPlaceName + ' '
          + this.worksheet.billingAddress.publicPlaceType + ' '
          + this.worksheet.billingAddress.streetNumber;
      }
      return ' ';
    },
  }
};
</script>

<style scoped>

</style>
